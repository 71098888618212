import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Registration } from './pages/Registration';
import { TopBar } from './components/TopBar';
import { Home } from './pages/Home';
import { CampInfo } from './pages/CampInfo';
import { Gallery } from './pages/Gallery';

function App() {
  return (
    <Router>
      <Fragment>
        <TopBar />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<CampInfo />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/register" element={<Registration />} />
        </Routes>
      </Fragment>
    </Router>
  );
}

export default App;
