import { S3 } from 'aws-sdk';

// Configure AWS SDK
export const s3Connection = new S3({
  region: process.env.REACT_APP_AWS_REGION, // Replace with your AWS region (e.g., "us-east-1")
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Replace with your AWS Access Key
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, // Replace with your AWS Secret Key
});

// Function to get presigned URL
export const getPresignedUrl = async (bucketName, key) => {
  const params = {
    Bucket: bucketName,
    Key: key,
    Expires: 60, // URL expires in 60 seconds
  };

  return new Promise((resolve, reject) => {
    s3Connection.getSignedUrl('getObject', params, (err, url) => {
      if (err) reject(err);
      resolve(url);
    });
  });
};

// Function to list all files in the bucket
export const listFiles = async (prefix = '') => {
  const bucketName = 'jp7images';

  try {
    const response = await s3Connection
      .listObjectsV2({
        Bucket: bucketName,
        Prefix: prefix, // Only fetch files from the "thumbnails" folder
      })
      .promise();
    const files = response.Contents.map((item) => ({
      key: item.Key, // File name (path inside bucket)
      url: `https://${bucketName}.s3.amazonaws.com/${item.Key}`, // Public URL
    }));

    return files.slice(1); // Return only the first 5 files
  } catch (error) {
    console.error('Error fetching files:', error);
    throw error;
  }
};
