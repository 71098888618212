import React from 'react';
import { CampInfoCard } from '../components/CampInfoCard';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import james_huddle from '../public/james_huddle.jpeg';
import { SocialLinks } from '../components/SocialLinks';

const StyledCampInfo = styled('div')({
  justifyContent: 'center',
  alignItems: 'center',
  height: `calc(100vh )`,
  position: 'relative',
  overflow: 'auto',
  backgroundColor: '#181A1B',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const StyledBox = styled(Box)(({ theme }) => ({
  width: '80%',
  maxWidth: '1200px',
  [theme.breakpoints.down('md')]: {
    width: '90%', // Change width to 96% on small screens
  },
  textAlign: 'center',
  margin: '2% auto',
}));

export const CampInfo = () => {
  return (
    <StyledCampInfo>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <StyledBox component="img" src={james_huddle} />
      </div>
      <CampInfoCard />;
      <div style={{ margin: 'auto', width: '100%' }}>
        <SocialLinks />
      </div>
    </StyledCampInfo>
  );
};
