import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { styled } from '@mui/system';

// MUI-styled container for the map
const StyledMapContainer = styled('div')(({ theme }) => ({
  margin: 'auto',
  maxWidth: '800px',
  width: '80%',
  height: '400px',
  marginBottom: theme.spacing(6),
  paddingBottom: '8px',
}));

export const MapComponent = ({ address }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [coordinates, setCoordinates] = useState(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    if (address && isLoaded) {
      getLatLngFromAddress(address, (location) => {
        if (location) {
          setCoordinates(location);
        } else {
          console.error('Could not fetch coordinates');
        }
      });
    }
  }, [address, isLoaded]);

  function getLatLngFromAddress(address, callback) {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;
        callback({
          lat: location.lat(),
          lng: location.lng(),
        });
      } else {
        callback({
          lat: 45.4200468,
          lng: -75.6789598,
        });
      }
    });
  }

  // Create AdvancedMarkerElement
  useEffect(() => {
    if (coordinates && isLoaded && mapRef.current) {
      if (markerRef.current) {
        markerRef.current.setPosition(coordinates);
      } else {
        markerRef.current = new window.google.maps.marker.AdvancedMarkerElement(
          {
            position: coordinates,
            map: mapRef.current,
          }
        );
      }
    }
  }, [coordinates, isLoaded]);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <StyledMapContainer>
      {coordinates ? (
        <GoogleMap
          zoom={14}
          center={coordinates}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          onLoad={(map) => (mapRef.current = map)}
        />
      ) : (
        <>Fetching location...</>
      )}
    </StyledMapContainer>
  );
};
