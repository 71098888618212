import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Dialog,
  DialogContent,
  Typography,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';
import { listFiles, getPresignedUrl } from '../utils/s3Utils.js'; // Adjust the path to your file
import textContent from '../public/en.json';
import { SocialLinks } from '../components/SocialLinks.js';

const StyledTypography = styled(Typography)(() => ({
  color: '#EEEEEE',
  fontFamily: 'Roboto, MS, sans-serif',
  width: '100%',
  textAlign: 'center',
  margin: 'auto',
  fontWeight: '100',
  paddingBottom: '16px',
  fontSize: '32px',
}));

const StyledImageBox = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  color: 'white',
  borderRadius: '8px',
  [theme.breakpoints.down('lg')]: {
    maxHeight: '20vh',
  },
  [theme.breakpoints.down('md')]: {
    maxHeight: '35vh',
  },
  [theme.breakpoints.down('sm')]: {
    maxHeight: '50vg',
  },
  [theme.breakpoints.down('xs')]: {
    maxHeight: '60vh',
  },
}));

export const Gallery = () => {
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 20;

  // Fetch thumbnails once component is mounted
  useEffect(() => {
    const fetchThumbnails = async () => {
      try {
        const thumbnails = await listFiles('Thumbnails/');
        const keys = thumbnails.map((thumbnail) => thumbnail.key);
        const images = await Promise.all(
          keys.map(async (key) => {
            const thumbUrl = await getPresignedUrl('jp7images', key);
            const fullUrl = await getPresignedUrl(
              'jp7images',
              key.replace('Thumbnails/', '1080p/')
            );
            return { thumb: thumbUrl, full: fullUrl };
          })
        );

        setImages(images);
      } catch (error) {
        console.error('Error fetching thumbnails:', error);
      }
    };

    fetchThumbnails();
  }, []);

  // Open image in dialog
  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  // Close dialog
  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  // Calculate the images to display for the current page
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  // Handle page navigation
  const handleNextPage = () => {
    if (currentPage < Math.ceil(images.length / imagesPerPage)) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#181A1B',
        minHeight: '100vh',
        padding: '2rem 0',
      }}
    >
      <StyledTypography>{textContent.gallery}</StyledTypography>

      <Box
        sx={{
          margin: '0 auto',
          width: '80%',
        }}
      >
        <Grid container spacing={2}>
          {currentImages.length > 0 ? (
            currentImages.map((image, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <StyledImageBox
                  component="img"
                  src={image.thumb}
                  alt={`Thumbnail ${index}`}
                  loading="lazy"
                  onClick={() => handleOpen(image.full)}
                  sx={{
                    objectFit: 'cover',
                    cursor: 'pointer',
                    borderRadius: '8px',
                  }}
                />
              </Grid>
            ))
          ) : (
            <Typography
              sx={{ color: '#fff', textAlign: 'center', width: '100%' }}
            >
              Loading images...
            </Typography>
          )}
        </Grid>
      </Box>

      {/* Pagination Controls */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '2rem auto',
          width: '80%',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <Typography
          sx={{
            color: '#fff',
            fontSize: '1rem',
          }}
        >
          Page {currentPage} of {Math.ceil(images.length / imagesPerPage)}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextPage}
          disabled={currentPage === Math.ceil(images.length / imagesPerPage)}
        >
          Next
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogContent sx={{ padding: 0 }}>
          {selectedImage && (
            <Box
              component="img"
              src={selectedImage}
              alt="Full-size"
              loading="lazy"
              sx={{ width: '100%', height: 'auto' }}
            />
          )}
        </DialogContent>
      </Dialog>
      <SocialLinks />
    </Box>
  );
};
