import { Modal, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  backgroundColor: '#25282A',
  border: 'none',
  outline: 'none', // Removes the focus outline
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
}));

const StyledTypography = styled(Typography)(() => ({
  color: '#EEEEEE',
  fontFamily: 'Roboto, MS, sans-serif',
  width: '80%',
  maxWidth: '800px',
  margin: '16px auto',
  fontWeight: '100',
  paddingBottom: '8px',
  fontSize: '16px',
}));

export const CoachModal = ({ open, handleClose, imageUrl, bio, name }) => {
  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      console.log('handle close');
      handleClose(); // Close the modal only if the backdrop is clicked directly
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropProps={{ invisible: true, onClick: handleBackdropClick }}
      sx={{ color: '#fff' }}
    >
      <StyledBox>
        <div style={{ textAlign: 'center' }}>
          <img
            src={imageUrl}
            alt={name}
            style={{
              width: '50%',
              margin: '1rem auto',
              border: '1px solid gray',
              borderRadius: '3%',
            }}
          />
        </div>
        {bio.map((paragraph) => (
          <StyledTypography variant="body2">{paragraph}</StyledTypography>
        ))}
      </StyledBox>
    </Modal>
  );
};
