import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, Avatar } from '@mui/material';
import { CoachModal } from './CoachModal';

// Styled Components
const CoachCardWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2), // Spacing between image and text
  marginBottom: theme.spacing(6), // Space between cards
}));

const CoachDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: '#EEEEEE',
  fontFamily: 'Roboto, MS, sans-serif',
  width: '80%',
  maxWidth: '800px',
  margin: '8px auto',
  fontWeight: 'thin',
  fontSize: '16px',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9em',
    width: '90%',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    width: '100%',
  },
}));

const CoachName = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  color: '#EEEEEE',
  fontFamily: 'Roboto, MS, sans-serif',
  width: '80%',
  maxWidth: '800px',
  margin: '8px auto',
  fontWeight: '500',

  [theme.breakpoints.down('md')]: {
    fontSize: '1.1rem',
    width: '90%',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    width: '100%',
  },
}));

const CoachTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1 rem',
  color: '#EEEEEE',
  fontFamily: 'Roboto, MS, sans-serif',
  width: '80%',
  maxWidth: '800px',
  margin: '8px auto',
  fontWeight: '100',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9em',
    width: '90%',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    width: '100%',
  },
}));

const CoachBio = styled('div')(({ theme }) => ({
  fontSize: '1 rem',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
  color: '#EEEEEE',
  fontFamily: 'Roboto, MS, sans-serif',
  width: '80%',
  maxWidth: '800px',
  margin: '16px auto',
  fontWeight: '100',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9em',
    width: '90%',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    width: '100%',
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 160,
  height: 160,
  [theme.breakpoints.down('md')]: {
    width: 120,
    height: 120,
  },

  [theme.breakpoints.down('sm')]: {
    width: 100,
    height: 100,
  },
}));

// CoachCard Component
export const CoachCard = ({ image, name, title, bio }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);

  return (
    <CoachCardWrapper>
      <CoachModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        imageUrl={image}
        bio={bio}
        name={name}
      />
      <StyledAvatar src={image} alt={`${name}'s profile`} />
      <CoachDetails>
        <CoachName>{name}</CoachName>
        <CoachTitle>{title}</CoachTitle>
        <CoachBio onClick={handleModalOpen}>View Bio</CoachBio>
      </CoachDetails>
    </CoachCardWrapper>
  );
};
