import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import jp7 from '../public/JP7-WHITE.png';

const StyledAppBar = styled(AppBar)({
  backgroundColor: '#1E2021', // Dark background color
  height: '48px',
  minHeight: 'unset !important',
  '& .css-hyum1k-MuiToolbar-root': {
    minHeight: '48px',
  },
});

const StyledTypography = styled(Typography)({
  flexGrow: 1,
});

const StyledLabel = styled(Typography)({
  fontFamily: 'Trebuchet MS, Tahoma, sans-serif',
  fontWeight: '100',
  fontSize: 'small',
  fontVariant: 'small-caps',
  textTransform: 'none',
  color: 'white', // White text color
});

const StyledLogo = styled('img')({
  height: '32px', // Adjust the height as needed
  margin: '8px 8px 0 8px', // Add margin for spacing
});

export const TopBar = () => {
  return (
    <StyledAppBar position="static">
      <Toolbar>
        <StyledTypography variant="h6" component="div">
          <a href="/">
            <StyledLogo src={jp7} alt="Logo" />
          </a>
        </StyledTypography>

        <Button color="inherit" component={Link} to="/">
          <StyledLabel>Home</StyledLabel>
        </Button>
        <Button color="inherit" component={Link} to="/register">
          <StyledLabel>Register</StyledLabel>
        </Button>
        <Button color="inherit" component={Link} to="/gallery">
          <StyledLabel>Gallery</StyledLabel>
        </Button>
        <Button color="inherit" component={Link} to="/about">
          <StyledLabel>About</StyledLabel>
        </Button>
      </Toolbar>
    </StyledAppBar>
  );
};
