// Import necessary dependencies
import React, { useState, useRef, useEffect } from 'react';
// import jwt from 'jsonwebtoken';
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Button,
  Checkbox,
  Alert,
  AlertTitle,
  Modal,
} from '@mui/material';
import axios from 'axios';
import JP7Waiver from '../public/JP7s_Waiver.pdf';

// Define the component
export const SignUpForm = () => {
  const [checked, setChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [size, setSize] = useState('');
  const [gender, setGender] = useState('');
  const [isError, setError] = useState(false);
  const [primaryPosition, setPrimaryPosition] = useState('');
  const [secondaryPosition, setSecondaryPosition] = useState('');
  const [formType, setFormType] = useState('');

  const formRef = useRef(null);

  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    if (isError) {
      const timer = setTimeout(() => {
        setError(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isError]);

  const calculateMinBirthDate = (formType) => {
    const today = new Date();
    if (formType && formType.toLowerCase() === 'SkillsCamp'.toLowerCase()) {
      return (today.getFullYear() - 12).toString();
    } else return (today.getFullYear() - 18).toString();
  };

  const calculateMaxBirthDate = (formType) => {
    const today = new Date();
    if (formType && formType.toLowerCase() === 'FootballShowcase'.toLowerCase())
      return (today.getFullYear() - 13).toString();
    else return (today.getFullYear() - 8).toString();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData(formRef.current);
    console.log(formData);
    const minBirthDate = calculateMinBirthDate(formType);
    const maxBirthDate = calculateMaxBirthDate(formType);
    const birthDate = new Date(formData.get('birthDate')); // Convert birth date string to Date object
    if (
      (birthDate.getFullYear() < minBirthDate ||
        birthDate.getFullYear() > maxBirthDate) &&
      birthDate !== undefined
    ) {
      // Display an error message or take appropriate action
      setError(true);
      if (formType.toLowerCase() === 'SkillsCamp'.toLowerCase()) {
        console.error(
          'Invalid birth date. Please enter a birth date between 8 and 13 years ago.'
        );
      } else if (formType.toLowerCase() === 'FootballShowcase'.toLowerCase()) {
        console.error(
          'Invalid birth date. Please enter a birth date between 12 and 18 years ago.'
        );
      }
      setSubmitting(false);
      return;
    }
    const data = {};
    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }

    try {
      const jwtResponse = await axios.get(
        `${serverUrl}/SignAuthTokenForRegister`
      );
      const jwt = jwtResponse?.data?.token;
      localStorage.setItem('jwtToken', jwt);

      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json', // Assuming JSON content type
      };

      const response = await axios.post(`${serverUrl}/register`, data, {
        headers,
      });
      if (response.data) {
        const _id = formType;
        const paymentSettings = {
          items: [
            {
              id: _id,
              quantity: 1,
            },
          ],
          formData: data,
        };

        const paymentRedirect = await axios.post(
          `${serverUrl}/create-checkout-session`,
          paymentSettings,
          { headers }
        );
        window.location.assign(paymentRedirect.data.url);
        setSubmitting(false);
      }
      // Add any further logic for successful signup
    } catch (error) {
      setSubmitting(false);
    }
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const PdfModal = ({ open, handleClose }) => {
    const handleBackdropClick = (event) => {
      if (event.target === event.currentTarget) {
        handleClose(); // Close the modal only if the backdrop is clicked directly
      }
    };

    return (
      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{ invisible: true, onClick: handleBackdropClick }}
      >
        <div>
          <div className="modalContent" style={{ zIndex: 10000 }}>
            <iframe
              src={JP7Waiver}
              style={{
                position: 'absolute',
                width: '80%',
                height: '80vh',
                marginTop: '10vh',
                marginLeft: '10vw',
                zIndex: 9999,
                border: 0,
              }}
              title="waiverForm"
            ></iframe>
            <div
              style={{
                width: '100vh',
                height: '100vh',
                position: 'absolute',
                zIndex: 0,
              }}
              className="customBackdrop"
              onClick={handleClose}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        overflow: 'auto',
        padding: '3%',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflowY: 'auto', // Make the Paper scrollable vertically
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        }}
      >
        <Typography component="h1" variant="h5" sx={{ marginBottom: 1 }}>
          Registration
        </Typography>
        {isError && (
          <Alert
            severity="error"
            style={{ width: '50%', position: 'fixed', zIndex: 10000000 }}
          >
            <AlertTitle>Error</AlertTitle>
            Invalid BirthDate
          </Alert>
        )}
        {/* Event Type Selection */}

        <form ref={formRef} onSubmit={handleSubmit} style={{ width: '100%' }}>
          <RadioGroup
            row
            name="formType"
            value={formType}
            required
            sx={{ justifyContent: 'center', margin: 'auto' }}
            onChange={(e) => setFormType(e.target.value)}
          >
            <FormControlLabel
              value="skillsCamp"
              control={<Radio />}
              label="Youth Skills Camp"
            />
            <FormControlLabel
              value="footballShowcase"
              control={<Radio />}
              label="Football Showcase"
            />
          </RadioGroup>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="First Name"
                name="firstName"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField required fullWidth label="Last Name" name="lastName" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Birthdate"
                type="date"
                name="birthDate"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField required fullWidth label="Address" name="address" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField required fullWidth label="City" name="city" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField required fullWidth label="Province" name="province" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField required fullWidth label="Country" name="country" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Postal Code"
                name="postalCode"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Phone Number" name="phoneNumber" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Email" type="email" name="email" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Parent/Guardian First Name"
                name="parentGuardianFirstName"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Parent/Guardian Last Name"
                name="parentGuardianLastName"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Parent/Guardian email"
                name="parentGuardianEmail"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Parent/Guardian Phone Number"
                name="parentGuardianPhoneNumber"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  required
                  fullWidth
                  displayEmpty
                  name="primaryPosition"
                  value={primaryPosition}
                  onChange={(e) => setPrimaryPosition(e.target.value)}
                >
                  <MenuItem disabled value="">
                    Select Primary Position
                  </MenuItem>
                  <MenuItem value="QB">QB</MenuItem>
                  <MenuItem value="RB">RB</MenuItem>
                  <MenuItem value="WR">WR</MenuItem>
                  <MenuItem value="DB">DB</MenuItem>
                  <MenuItem value="LB">LB</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  fullWidth
                  displayEmpty
                  name="secondaryPosition"
                  value={secondaryPosition}
                  onChange={(e) => setSecondaryPosition(e.target.value)}
                >
                  <MenuItem disabled value="">
                    Select Secondary Position
                  </MenuItem>
                  <MenuItem value="QB">QB</MenuItem>
                  <MenuItem value="RB">RB</MenuItem>
                  <MenuItem value="WR">WR</MenuItem>
                  <MenuItem value="DB">DB</MenuItem>
                  <MenuItem value="LB">LB</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  required
                  fullWidth
                  displayEmpty
                  name="gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem disabled value="">
                    Select Gender
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  required
                  fullWidth
                  displayEmpty
                  name="size"
                  value={size}
                  onChange={(e) => setSize(e.target.value)}
                >
                  <MenuItem disabled value="">
                    Select Size
                  </MenuItem>
                  <MenuItem value="S/M">S/M</MenuItem>
                  <MenuItem value="L/XL">L/XL</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Dietary Food Restrictions"
                name="dietaryRestrictions"
              />
            </Grid>
          </Grid>

          <Checkbox
            checked={checked}
            required
            onChange={handleCheckboxChange}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Button>
            <div
              style={{ fontFamily: 'Trebuchet MS', textTransform: 'none' }}
              onClick={handleModalOpen}
            >
              Terms and Waiver
            </div>
            <PdfModal open={modalOpen} handleClose={handleModalClose} />
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            disabled={isSubmitting}
          >
            Sign Up
          </Button>
        </form>
      </Paper>
    </Container>
  );
};
