import { useState, useEffect } from 'react';
import { Box, Collapse, IconButton, Grid, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/system';
import textContent from '../public/en.json';
import { getPresignedUrl, listFiles } from '../utils/s3Utils';

const StyledTypography = styled(Typography)(() => ({
  color: '#EEEEEE',
  fontFamily: 'Roboto, MS, sans-serif',
  width: '80%',
  maxWidth: '800px',
  fontWeight: '100',
  paddingBottom: '8px',
  fontSize: '24px',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  width: '800px',
  margin: 'auto',
  color: 'white',
  borderRadius: '8px',
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  },
  [theme.breakpoints.down('xs')]: {
    width: '98%',
  },
}));

export const CampGalleryHome = () => {
  const [open, setOpen] = useState(true);
  const [images, setImages] = useState([]);

  // Fetch thumbnails once component is mounted
  useEffect(() => {
    const fetchThumbnails = async () => {
      try {
        const thumbnails = await listFiles('Thumbnails/');
        const keys = thumbnails.map((thumbnail) => thumbnail.key);
        const images = await Promise.all(
          keys.map(async (key) => {
            const thumbUrl = await getPresignedUrl('jp7images', key);
            return thumbUrl;
          })
        );

        setImages(images);
      } catch (error) {
        console.error('Error fetching thumbnails:', error);
      }
    };

    fetchThumbnails();
  }, []);

  const imageList = images.slice(9, 15);

  const toggleCollapse = () => {
    setOpen(!open);
  };

  return (
    <StyledBox>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <StyledTypography>{textContent.gallery}</StyledTypography>
        <IconButton onClick={toggleCollapse} color="inherit">
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid container spacing={1}>
          {imageList.map((src, index) => (
            <Grid item xs={6} sm={4} key={index}>
              <Box
                component="img"
                src={src}
                alt={`Gallery ${index}`}
                loading="lazy"
                sx={{
                  maxWidth: '100%',
                  maxHeight: '150px',
                  objectFit: 'cover',
                  borderRadius: '4px',
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </StyledBox>
  );
};
