import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { Instagram, Facebook, Email, Link } from '@mui/icons-material';

const StyledBox = styled(Box)(() => ({
  color: '#EEEEEE',
  margin: 'auto',
  paddingBottom: '64px',
  width: '80%',
  maxWidth: '800px',
  textAlign: 'center',
}));

const FooterLink = styled('a')(() => ({
  color: '#EEEEEE',
  textDecoration: 'none',
  margin: '0 1rem',
}));

export const SocialLinks = () => {
  return (
    <StyledBox>
      <FooterLink href="https://www.instagram.com/jamespeter7s?igsh=MXZvbDcyMXVoNTlxOA%3D%3D&utm_source=qr">
        <IconButton color="inherit" aria-label="Instagram">
          <Instagram />
        </IconButton>
      </FooterLink>

      <FooterLink href="https://www.facebook.com/share/qzFEP4QLaGTQHR2e/?mibextid=hu50Ix">
        <IconButton color="inherit" aria-label="Facebook">
          <Facebook />
        </IconButton>
      </FooterLink>
      <FooterLink href="https://linktr.ee/JamesPeter7sFootball?utm_source=linktree_profile_share&ltsid=30d704b3-2ed3-418e-af66-8622eac3b5fe">
        <IconButton color="inherit" aria-label="Share">
          <Link />
        </IconButton>
      </FooterLink>
      <FooterLink href="mailto:JP7sfootball@gmail.com">
        <IconButton color="inherit" aria-label="Email">
          <Email />
        </IconButton>
      </FooterLink>
    </StyledBox>
  );
};
