import React from 'react';
import { SignUpForm } from '../components/SignUpForm';
import { styled } from '@mui/system';
import { SocialLinks } from '../components/SocialLinks';

const StyledForm = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: `calc(100vh -72px)`,
  position: 'relative',
  flexDirection: 'column',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#181A1B',
    backgroundSize: 'cover',
    zIndex: -1,
  },
});

export const Registration = () => {
  return (
    <StyledForm>
      <SignUpForm
        registrationType="footballShowcase"
        formName="Youth Football Showcase"
        formType="FootballShowcase"
      />
      <SocialLinks />
    </StyledForm>
  );
};
